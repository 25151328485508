import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Commission.Item, State.Root> = {
  async Find({ commit }, { COMMISSION_ID, ITEM_ID }) {
    const res = await Api().get(`commissions/${COMMISSION_ID}/items/${ITEM_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { COMMISSION_ID, query }) {
    const res = await Api().get(`commissions/${COMMISSION_ID}/items`, {
      params: query,
    });

    commit('PushList', res.data);

    return res.data;
  },
  async Pay(context, { COMMISSION_ID, ITEM_ID, form }) {
    const res = await Api().post(`commissions/${COMMISSION_ID}/items/${ITEM_ID}`, form);

    return res.data;
  },
};

export default actions;
