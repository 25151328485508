import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Commission.Message, State.Root> = {
  Find: (s) => (id: string): ApiResource.CommissionMessage | undefined => s.list
    .filter((m) => m.id === id)[0],
  List: (s) => (c: string): ApiResource.CommissionMessage[] => s.list
    .filter((m) => m.commission_id === c),
};

export default getters;
