import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Commission.File, State.Root> = {
  Find: (s) => (id: string): ApiResource.CommissionFile | undefined => s.list
    .filter((f) => f.id === id)[0],
  List: (s) => (c: string): ApiResource.CommissionFile[] => s.list
    .filter((f) => f.commission_id === c),
};

export default getters;
