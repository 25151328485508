import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Commission.Item, State.Root> = {
  Find: (s) => (id: string): ApiResource.CommissionItem | undefined => s.list
    .filter((i) => i.id === id)[0],
  List: (s) => (c: string): ApiResource.CommissionItem[] => s.list
    .filter((i) => i.commission_id === c),
};

export default getters;
