import { ActionTree } from 'vuex';
import { State, UploadInstructions } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Commission.File, State.Root> = {
  async Confirm({ commit }, { COMMISSION_ID, FILE_ID, data }) {
    const res = await Api().put(
      `commissions/${COMMISSION_ID}/files/${FILE_ID}`,
      data,
    );

    commit('Push', res.data);

    return res.data;
  },
  async Create({ commit }, { COMMISSION_ID, form }) {
    const res = await Api().post(
      `commissions/${COMMISSION_ID}/files`,
      form,
    );

    commit('Push', res.data);

    return res.data;
  },
  async Delete({ commit }, { COMMISSION_ID, FILE_ID }) {
    const res = await Api().delete(`commissions/${COMMISSION_ID}/files/${FILE_ID}`);

    commit('Remove', FILE_ID);

    return res.data;
  },
  async Download(context, { COMMISSION_ID, FILE_ID }) {
    const res = await Api().post(
      `commissions/${COMMISSION_ID}/files/${FILE_ID}/download`,
    );

    return res.data;
  },
  async Find({ commit }, { COMMISSION_ID, FILE_ID }) {
    const res = await Api().get(`commissions/${COMMISSION_ID}/files/${FILE_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { COMMISSION_ID, query }) {
    const res = await Api().get(`commissions/${COMMISSION_ID}/files`, {
      params: query,
    });

    commit('PushList', res.data);

    return res.data;
  },
  async Upload(context, { COMMISSION_ID, FILE_ID }) {
    const res = await Api().post(
      `commissions/${COMMISSION_ID}/files/${FILE_ID}`,
    );

    return res.data;
  },
  async UploadPipeInstructions(
    { rootGetters },
    { COMMISSION_ID, FILE_ID },
  ): Promise<UploadInstructions> {
    return {
      object: 'upload',
      file: 'FORM_DATA_APPEND_PARAM:File',
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/commissions/${COMMISSION_ID}/files/${FILE_ID}/upload`,
      headers: {
        Authorization: rootGetters['oauth2/AuthorizationHeader'],
      },
      params: {},
      query: null,
    };
  },
};

export default actions;
