/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Commission.Item> = {
  Push(state, { data }: { data: ApiResource.CommissionItem }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.CommissionItem[] }) {
    data.forEach((m) => pushResource(state.list, m));
  },
};

export default mutations;
