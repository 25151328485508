import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Commission.Trackers, State.Root> = {
  async Find({ commit }, { COMMISSION_ID, TRACKER_ID }) {
    const res = await Api().get(`commissions/${COMMISSION_ID}/trackers/${TRACKER_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { COMMISSION_ID, query }) {
    const res = await Api().get(
      `commissions/${COMMISSION_ID}/trackers`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
  async Create({ commit }, { COMMISSION_ID, form }) {
    const res = await Api().post(
      `commissions/${COMMISSION_ID}/trackers`,
      form,
    );

    commit('Push', res.data);

    return res.data;
  },
};

export default actions;
