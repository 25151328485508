import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Commission.Event, State.Root> = {
  async List({ commit }, { COMMISSION_ID, query }) {
    const res = await Api().get(
      `commissions/${COMMISSION_ID}/events`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
};

export default actions;
